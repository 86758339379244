import { makeStyles } from '@material-ui/core';


const viewPartStyles = makeStyles(theme => ({
    root: {
        maxHeight: 'calc(100vh - var(--nh, 1vh))',
        width: '100%',
        maxWidth: '1140px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        
        '& .part-list-wrapper': {
            width: '100%',
            maxWidth: '675px',
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',

            '& .filter-control-container': {
                padding: theme.spacing(3),
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(2),
                },
                marginBottom: theme.spacing(1),
            },
            '& .list-container': {
                marginBottom: theme.spacing(1),
                overflowY: 'auto',
                flex: 1,
                justifyContent: 'center',
                
            }
        },        
        '& .MuiList-root': {
            padding: theme.spacing(0, 1),
        },        
        '& .MuiListItem-root': {
            border: '1px solid #140a9a',
                        
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1),
            }
        },        
        '& .MuiGrid-container .MuiGrid-container': {
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(2),
            },
            flexWrap: 'nowrap',
            
            '&:first-child': {
                paddingTop: theme.spacing(0),
                flexGrow: 1,
                
                '& .MuiGrid-item:last-child': {
                    flexShrink: 0, 
                    flexBasis: 1,          
                }
            },
            '& .MuiGrid-item': {
                flexGrow: 1,
                '&:first-child':{
                    paddingRight: theme.spacing(2),
                },
                '& .MuiFormControl-root': {
                    width: '100%'
                }
            },
            '&:last-child': {
                paddingTop: theme.spacing(2),

                '& .MuiButton-root': {
                    backgroundColor: '#140a9a',
                    color: 'white',
                    
                    '&:hover': {
                        opacity: 0.95
                    },                    
                    '& > .MuiButton-label': {
                        justifyContent: 'center',
                        textTransform: 'capitalize'
                    },
                },
                
            }
            
        },

        '& .results': {
            paddingTop: 0,
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            '& span': {
                fontWeight: 'bold',
            },
            fontWeight: '400',
            [theme.breakpoints.down(675)]: {
                fontSize: 12,
                justifyContent: 'center',
                '& span': {
                    fontSize: 13,
                }
            },
            '& > .MuiGrid-item.MuiGrid-item': {
                paddingRight: theme.spacing(2),
                flexGrow: 0,
                flexBasis: 'unset',
                flexShrink: 'unset',
            }
        }
    },

    
}))

export default viewPartStyles;
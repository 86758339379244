import { makeStyles } from '@material-ui/core';

const permissionStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        maxHeight: '100%',
        '& .user-list-container': {
            width: '100%',
            maxWidth: '725px',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            '& .scrollable-list': {
                marginTop: theme.spacing(1),
                maxHeight: '100%',
                overflow: 'auto'

            },
            
            '& > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
                paddingLeft: theme.spacing(1)
            }
        },
        '& .user-item': {
            '& span': {
                textShadow: '0 0 1px currentColor',
            },
            '& > strong': {
                marginLeft: theme.spacing(2),
                color: '#140a9a'
            }
        }
    },
    searchArea: {
        marginTop: theme.spacing(2),
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
        gridColumnGap: theme.spacing(2),
        [theme.breakpoints.down(575)]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',                  
            gridRowGap: theme.spacing(1),
        },
        '& .input-with-btn': {
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            '& .MuiInputBase-root': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                
            },
            '& .input-end-btn': {
                marginLeft: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                '& .MuiButton-label': {
                    justifyContent: 'center'
                }
            }
        },
        '&.MuiPaper-root': {
            padding: theme.spacing(3,2)
        },
        '& .theme-btn': {
            color: 'white',
            backgroundColor: '#140a9a',
           
            transition: 'opacity 150ms linear',
            '&:hover': {
                opacity: '0.8',
            },
            '&:disabled': {
                backgroundColor: 'lightgrey',
                color: 'darkgrey'
            },
            '& .MuiButton-label': {
                justifyContent: 'center',
            }
        },

        '& .switch-group': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            position: 'relative',         
            
        }

    },
    modalRoot: {
        position: 'absolute',
        width: '100%',
        maxWidth: '675px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '& .modal-body': {
            position: 'relative',
            '& .close-button': {
                position: 'absolute',
                top: 0,
                right: 0,
            },
            '& .MuiGrid-item > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
            },
            '& .MuiAlert-root': {
                marginTop: theme.spacing(2),
                fontSize: 15
            }
        }
    }
}));

export default permissionStyles;
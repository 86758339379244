import React, { useEffect, useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SvgIcon from '@material-ui/core/SvgIcon';
import Backdrop from '@material-ui/core/Backdrop';
import Popover from '@material-ui/core/Popover';
import { CloseIcon } from '../../icons';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeatureForm from './Forms/featureForm';
import BugForm from './Forms/bugForm';
import useStyles from './featureStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const FeatureRequest = () => {

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:500px)');
    
    const [ open, toggleOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ activeBtn, setActiveBtn ] = useState(null);
    const [ form, setForm ] = useState(null);

    const menuItems = [
        {
            title: "Request a Feature",
            svgPath: <path d="M23 12l-2.44-2.78.34-3.68-3.61-.82-1.89-3.18L12 3 8.6 1.54 6.71 4.72l-3.61.81.34 3.68L1 12l2.44 2.78-.34 3.69 3.61.82 1.89 3.18L12 21l3.4 1.46 1.89-3.18 3.61-.82-.34-3.68L23 12zm-10 5h-2v-2h2v2zm0-4h-2V7h2v6z"></path>,
            formComponent: FeatureForm
        },
        {
            title: "Report a Bug",
            svgPath:  <path d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z"></path>,
            formComponent: BugForm
        }
    ];
    
    const handlePopoverClose = () => {

        activeBtn.classList.toggle("active");
        
        setActiveBtn(null);
        setAnchorEl(null);
        setForm(null);
    }       

    const handlePopoverOpen = formComponent => e => {        
        const FormComponent = formComponent;
        if(activeBtn) {
            handlePopoverClose();
        }
        e.currentTarget.classList.toggle("active");
        setForm(<FormComponent />);
        setActiveBtn(e.currentTarget);
        setAnchorEl(e.currentTarget.parentElement.parentElement);
    }

    const handleToggle = () => {
        if(activeBtn) {
            handlePopoverClose();
        }
        toggleOpen(!open);
    } 
    
    return (
        <div style={{ position: 'relative' }}>
            <Backdrop open={open} onClick={handleToggle} style={{ zIndex: 1000 }} />
            {/* <Backdrop open={Boolean(anchorEl)} onClick={() => console.log("foo")} style={{ zIndex: 1250, backgroundColor: 'rgba(0,0,0,0.25)' }} />  */}           
            <SpeedDial
                direction="up"
                ariaLabel="feature-and-bug-request"
                className={classes.root}                
                FabProps={{
                    size: matches ? "small" : "medium",
                    className: classes.mainButton,
                    onClick: handleToggle
                }}
                icon={                    
                    <SpeedDialIcon 
                        icon={
                            <SvgIcon>
                                <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"></path>
                            </SvgIcon>
                        } 
                        openIcon={
                            <SvgIcon>
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </SvgIcon>
                        }
                    />                                        
                }
                open={open} 
            >
            {
                menuItems.map(({ title, svgPath, formComponent}, i) => (
                    <SpeedDialAction 
                        key={i}
                        classes={{fab: classes.fabClass}}
                        icon={
                            <SvgIcon>
                                {svgPath}
                            </SvgIcon>
                        } 
                        tooltipTitle={title}
                        FabProps={{
                            size: matches ? "small" : "medium",
                            onClick: handlePopoverOpen(formComponent)                      
                        }}                           
                    />
                ))
            }
            </SpeedDial>            
            <Popover
                onBackdropClick={handlePopoverClose}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                style={{zIndex: 1000}}
                classes={{
                    paper: classes.paper
                }}                
                anchorOrigin={{
                    vertical:  'bottom',
                    horizontal: -12,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PopoverBody classes={classes} closeFunc={handlePopoverClose}>
                    {form}
                </PopoverBody>
            </Popover>
        </div>
    )
}

export default FeatureRequest;

const PopoverBody = ({ classes, children, closeFunc=()=>{} }) => {
    
    return (
        <div className={classes.popoverBody}>            
            <IconButton size="small" onClick={closeFunc}>
                <CloseIcon />
            </IconButton>
            {children}
        </div>
    )
}


export const LoadingOverlay = ({ loading, loadText }) => {
    const [ visible, setVisible ] = useState(false);

    useEffect(() => {        
        setVisible(loading);
    }, [loading]);

    return(
        <div className={`loading-overlay${visible ? "-visible" : ""}`}>
            {loadText && <div className="loading-text">{loadText}</div>}
            <CircularProgress color="primary" />
        </div>
    )
}

export const HelperText = ({min="", max = ""}) => {
    const classes=useStyles();
    return(
        <div className={classes.helperContainer}>
            <div>{min}</div>
            <div>{max}</div>
        </div>
    )
}
//project Id 90789b0f-7a4f-4d32-99de-08b97453307b
//token zvewtmzl7hpi7k7j2gnjd4s2rxirpyqrymvbe3rwwvnyaccfsqya
//api encoded base64 string "ZXJpay53aGl0ZUBtaW5pbmcua29tYXRzdTp6dmV3dG16bDdocGk3azdqMmduamQ0czJyeGlycHlxcnltdmJlM3J3d3ZueWFjY2ZzcXlh"
import React, { useEffect, useRef } from "react";
import { Button, SvgIcon, Badge } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { useLocation, Link } from "react-router-dom";
import klogo from "../../images/klogo-trimmed.png";
import logoRevision from '../../images/logo-revision.png'; 
import useConnection from './useConnection';
import navStyles from './navStyles';
import './nav.css';
import CollapsibleMenuItem from "./collapsibleMenu";
import baseUrl from "../../baseUrl";
import { authProvider } from '../../authProvider';

const NavBar = ({ shelfNavItems, appHeaderText, badgeState={}, permissions={}, accountInfo }) => {
  const nav = useRef(); 
  const toggle = useRef();
  const shelf = useRef();
  const overlay = useRef();
  const location = useLocation();
  const classes = navStyles();
  const dispatch = useDispatch();
  const [ connection ] = useConnection(`${baseUrl}/hubs/notificationhub`, accountInfo.jwtIdToken);
  

  const setClientNavOffsetHeight = () => {
    let nh = nav.current.clientHeight;
    
    document.documentElement.style.setProperty('--nh', `${nh}px`);
  }

  const handleToggle = ({ target: { checked } }) => {
    const x = window.scrollX;
    const y = window.scrollY;
    checked 
      ? window.onscroll = () => window.scrollTo(x,y)
      : window.onscroll = () => {};
    setTimeout(() => overlay.current.classList.toggle('active'), 50);  
    setTimeout(() => shelf.current.classList.toggle('open'), 85);
  }

  const handleNavClick = () => {
    const { current } = toggle;
    current.checked = !current.checked;
    handleToggle({ target: { checked: current.checked }});
  }
  
  

  useEffect(() => {
    
    window.addEventListener('resize', setClientNavOffsetHeight);

    return () => window.removeEventListener('resize', setClientNavOffsetHeight);
  }, []);

  useEffect(() => {
    const openConnection = async () => {
      try {
        
        return await connection.start()
      } catch (err) {
          
        console.log(err);
        setTimeout(() => openConnection(), 5000);
      }
    };

    if(Object.keys(permissions).length > 0 && shelfNavItems.length > 0){
      if(permissions?.isAdmin && connection && !connection._connectionStarted) {

        const badgeNames = shelfNavItems.find(({ label }) => label === "Admin Tools")
          .children.filter(({ displayBadge = null }) =>  displayBadge ).map(({ badgeName }) => badgeName); 

        badgeNames.forEach( badgeName => {
          connection.on(badgeName, badgeObj => {  
            dispatch({ 
              payload: { 
                counts: badgeObj
              },
              type: "SET_BADGE_STATE" 
            })
          })
        });

        openConnection()
        /* console.log(connection); */
          .then(async () => {
            connection.onclose(async () => {
              await authProvider.getIdToken();
              openConnection();
            });
            await connection.invoke("SubscribeOnConnection", badgeNames);
            await connection.invoke("GetInitialBadgeState", badgeNames);
          });
      }
    }

  }, [ permissions, connection, shelfNavItems, dispatch]);

  
  return (
    <nav ref={ nav } id="nav" onLoad={setClientNavOffsetHeight} className={`navbar ${classes.navRoot} ${classes.navColor}`}>      
      <div className="toggle-container">
      {
        shelfNavItems && shelfNavItems.length > 0 &&
          
            <div className="side-nav-toggle">
              <Badge color="error" invisible={!badgeState.active}>
                <input type="checkbox" onChange={ handleToggle } ref={ toggle } id="check" />
                <label htmlFor="check">
                  <div className="btn1" id="btn1">
                    <span />
                    <span />
                    <span />
                  </div>
                </label>
              </Badge>
            </div>
          
      }
      </div>
      <div className="container">
        
        <div className="navbar-header">
          <Link to="/">
            <div className='logo-container'>
              <img src={ logoRevision } className="img-fluid app-logo" alt="kpng-logo" />
                           
            </div>
          </Link>
          <h1 className="nav navbar-nav navbar-right">
            <img src={klogo} className="k-logo" alt="komatsu-logo" /> { appHeaderText }
          </h1>
        </div>
        
      </div>
      {
        shelfNavItems && shelfNavItems.length > 0 &&
        <div id="side-nav">
          <div className="side-nav-overlay" onClick={handleNavClick} ref={ overlay } />
          <div className="side-nav-shelf" ref={ shelf }>
            
          {
            shelfNavItems.map(({ label, to=null, iconPath=null, children=null }, i) => {
              
              const active = location.pathname === to;
              const activeClass = active ? " active" : "";
              const clickFunc = active ? e => e.preventDefault() : handleNavClick;
              return(
                !children 
                ?
                  <Button
                    component={Link}
                    to={to}
                    key={i} 
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={`side-nav-item${activeClass}`} 
                    startIcon={
                      iconPath &&
                        <SvgIcon style={{color: "#140a9a"}}>
                          {iconPath}
                        </SvgIcon>
                    }                    
                    onClick={clickFunc}>  
                    
                      {label}
                      
                  </Button>
                : 
                  <CollapsibleMenuItem 
                    key={i} 
                    label={label}  
                    iconPath={iconPath} 
                    items={children}
                    badgeState={badgeState} 
                    handleItemClick={handleNavClick}
                  />
              )
            })
          }
          </div>
        </div>
      }  
    </nav> 
  );
};

export default NavBar;

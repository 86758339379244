import { Grid, Paper, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction, SvgIcon, Modal, Divider, Typography, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import ModalBody from './pendingRequestsModalBody';
import useAdminStyles from '../adminStyles';
import { useDispatch } from 'react-redux';
import fetchFromApi from '../../../apiFetch';


const PendingRequests = () => {
    
    const dispatch = useDispatch();
    const classes = useAdminStyles();

    const [ pendingRequests, setPendingRequests ] = useState(null); 
    const [ updatingRequests, setUpdatingRequests ] = useState(false);
    const [ errorUpdating, setErrorUpdating ] = useState(false);   
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalBody, setModalBody ] = useState(undefined);

    const getPendingRequests = useCallback(async () => {
        
        setUpdatingRequests(true);

        await fetchFromApi({ controller: "admin", method: "getPendingRequests", refreshToken: true })            
            .then(data => {
                
                setUpdatingRequests(false);
                setPendingRequests(data.requests);
                dispatch({ 
                    type: 'SET_BADGE_STATE', 
                    payload: {                        
                        counts: {
                            access: data.requests.length
                        }
                    }
                })
            })
            .catch(error => {
                console.log(error);
                setErrorUpdating(true);
                setUpdatingRequests(false);
            });

    }, [dispatch]);

    useEffect(() => {
        
        if(!pendingRequests) { 
            
            getPendingRequests();            
        }
    }, [ getPendingRequests, pendingRequests]);

    const handleModalOpen = request => () => {       
        const closeAction = () => {
            handleModalClose();
            getPendingRequests();
        }
        
        setModalBody(
            <ModalBody                 
                requestDetails={ request } 
                closeFunc={ closeAction }
            />
        );
        setModalOpen(true);
    }

    const handleModalClose = () => {        
        setModalOpen(false);
        
        setModalBody(undefined);
    }

    return(
       
        <Grid
            container
            direction='row'
            justify='space-around'
            alignContent='center'
        >
            <Grid className={classes.controlListItemWidth} item>
                <Paper variant="outlined" square>
                    <Typography variant="h6" className={classes.title}>
                        Pending Access Requests
                    </Typography>
                    <Divider />
                    {
                        !updatingRequests
                            ?
                                <List dense>
                                {
                                    !errorUpdating && pendingRequests && pendingRequests.length > 0 
                                    ?
                                        pendingRequests.map((req,ind) => {
                                            return (
                                                
                                                <ListItem 
                                                    button
                                                    key={ind} 
                                                    divider={
                                                        ind !== pendingRequests.length - 1 
                                                    }
                                                    onClick={handleModalOpen(req)}
                                                >
                                                    <ListItemText                                                        
                                                        primary={req.displayName}
                                                        secondary={req.email}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton 
                                                            edge="end"              
                                                            aria-label="update status" 
                                                            onClick={handleModalOpen(req)}
                                                        >
                                                            <SvgIcon>
                                                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path> 
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })
                                    :   
                                        !errorUpdating
                                            ?
                                                <ListItem>
                                                    <ListItemText 
                                                        align="center" 
                                                        className={classes.subtitle} 
                                                        primary="No pending requests" 
                                                    />
                                                </ListItem>
                                            : 
                                                <ListItem>
                                                    <ListItemText 
                                                        align="center" 
                                                        className={classes.subtitle} 
                                                        primary="Some kind of error occurred try refreshing the page" 
                                                    />
                                                </ListItem>
                                }                                        
                                </List> 
                            :
                                <Grid container justify="center">
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                    }                  
                </Paper>                         
            </Grid>
            <Modal 
                open={modalOpen} 
                onClose={handleModalClose}
                disableBackdropClick
            >  
                <div 
                    style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} 
                    className={classes.paper}
                >
                { modalBody }
                </div>
            </Modal>
        </Grid>
    );
}

export default PendingRequests;
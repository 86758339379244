import baseUrl from './baseUrl';
import endpoints from './endpoints';
import store from './store';
import { authProvider } from './authProvider';

const fetchFromApi = async ({controller = "", method = "", fetchOptions = {}, params = [], refreshToken=false}) => {
    //refresh token on action if specified
    if(refreshToken) {
        await authProvider.getIdToken();
    }
    const queryParams = params.join("/");
    
    const url = `${baseUrl}${endpoints[controller].base}${endpoints[controller][method]}${queryParams}`; 
    const { jwtIdToken } = store.getState().auth.aadResponse;
    
    const options = {
        ...fetchOptions,
        headers: {
            authorization: `Bearer ${jwtIdToken}`,
            'Content-Type': 'application/json',
            ...fetchOptions?.headers
        },        
    }
    
    const response = await fetch(url, options)
    
    if (response.ok) {
        return response.json(); 
    } else {
        
        return response;
    }
        
}

export default fetchFromApi;
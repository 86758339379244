import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './additionalRequestStyles';
import postToApi from '../../apiFetch';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';


function getStyles(permission, permissionNames) {
    const selected = permissionNames.indexOf(permission) !== -1
    return {
      fontWeight: selected ? 600 : 400,
      backgroundColor: selected ? 'rgba(20, 10, 154, 0.25)' : 'transparent'
    };
}

const AdditionalRequest = () => {
    const userPermissions = useSelector(store => store.auth.user.permissions);
    const permissions = Object.keys(userPermissions)
        .filter(permission => !userPermissions[permission]);        
    
    const classes = useStyles();
    const mounted = useRef(false);
    const [ selectedPermissions, setSelectedPermissions ] = useState([]);
    const [ justification, setJustification ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ responseMsg, setResponseMsg ] = useState(undefined);

    const [ loaded, setLoaded ] = useState(false);
    const [ hasRequest, setHasRequest ] = useState(false);
    const [ hasError, setHasError ] = useState(false);

    const checkForRequest = useCallback(() => {
        postToApi({
            controller: "users",
            method: "checkAdditionalRequest",            
            refreshToken: true,
        })
        .then(data =>{ 
            if(mounted.current){
                setLoaded(true);
                setHasRequest(data.hasRequest);
            }
        })
        .catch(() => {
            setHasError(true);
            setLoaded(true);
        })

    }, []);

    useEffect(() => {
        mounted.current = true;
        if(!loaded){
            
            checkForRequest();
        }
        return() => {
            mounted.current = false;
        }
    }, [ checkForRequest, loaded, mounted ]);

    const handleSelect = e => {
       
        setSelectedPermissions(e.target.value);
    };

    const handleUpdate = e => {
        if(e.target.value.length <= 750)
            setJustification(e.target.value);
    }
    
    const handleSubmit = () => {
        const request = {
            requestJustification: justification,
            additionalPermissions: Object.fromEntries(selectedPermissions.map(perm => [perm, true]))
        }
        setSubmitting(true);

        postToApi({
            controller: "users",
            method: "submitAdditionalRequest",
            fetchOptions: {
                method: 'POST',
                body: JSON.stringify(request)
            },
            refreshToken: true,
        })
        .then(data => {
            if(mounted.current){
                setResponseMsg(data.message);
                setTimeout(() => mounted.current && setHasRequest(data.updated), 3000);            
                setSubmitting(false);
            }
        })
        .catch(() => { 
            setResponseMsg("Error");
            setSubmitting(false);
            setHasError(true);
        });
    }    

    return(
        <div className={`${classes.root} full-height`}>
            <Paper square elevation={3}> 
                <Typography variant="h6">Request Additional Access</Typography>
                <Divider /> 
                {
                    !loaded 
                        ?  <CircularProgress /> 
                        :   !hasRequest && !hasError
                            ?   <Fragment>
                                {
                                    !responseMsg
                                    ?
                                        <Fragment>
                                            <FormControl className={classes.formControl}>                    
                                                <TextField
                                                    variant="outlined"
                                                    label="Select Permissions"
                                                    select                                    
                                                    SelectProps={{
                                                        multiple: true,
                                                        value: selectedPermissions,
                                                        MenuProps: {
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null                                            
                                                        },
                                                        renderValue: (selected) => (
                                                            <div className={classes.chips}>
                                                            {
                                                                selected.map((value) => (
                                                                    <Chip 
                                                                        key={value} 
                                                                        label={value.split(/(?=[A-Z])/).join(" ")} 
                                                                        className={classes.chip}
                                                                    />
                                                                ))
                                                            }
                                                            </div>
                                                        )
                                                    }}
                                                    onChange={handleSelect}                        
                                                >
                                                {
                                                    permissions.map((permission) => (
                                                        <MenuItem
                                                            key={permission}
                                                            value={permission.replace(/(can)|(is)/g, "")}
                                                            style={getStyles(permission.replace(/(can)|(is)/g, ""), selectedPermissions)}
                                                        >
                                                            {permission.split(/(?=[A-Z])/).slice(1).join(" ")}
                                                        </MenuItem>
                                                    ))
                                                }
                                                </TextField>
                                            </FormControl>
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Justification"
                                                    value={justification}
                                                    onChange={handleUpdate}
                                                    multiline
                                                    rows={7}
                                                    rowsMax={7}
                                                />
                                            </FormControl>
                                            <FormControl className={classes.formControl}>
                                            {
                                                !submitting
                                                    ?
                                                    <Button
                                                        fullWidth
                                                        variant="contained" 
                                                        disabled={selectedPermissions.length === 0 || justification.length === 0} 
                                                        onClick={handleSubmit}
                                                    >
                                                        Submit Request
                                                    </Button>
                                                    :
                                                    <CircularProgress color="primary" />
                                            }
                                            </FormControl>
                                        </Fragment>
                                    : 
                                        <Typography align="center">{responseMsg}</Typography>
                                        
                                }
                                </Fragment>
                            : !hasError
                                ? <Typography align="center">You have a pending additional access request.  An administrator will review it as soon as possible.</Typography>
                                : <Typography align="center">Error</Typography>
                    }
            </Paper>               
        </div>
    )
}

export default AdditionalRequest;
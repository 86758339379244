import React, { useCallback, useEffect, useState, useRef } from 'react';
import { CircularProgress, Box, List, ListItem, ListItemText } from '@material-ui/core';
import FilterToolbar from './partFilterBar';
import fetchFromApi from '../../apiFetch';
import useStyles from './viewPartStyles';

const ViewParts = ({ userId = null }) => {
    const mounted = useRef();
    const { root } = useStyles();

    const [ partList, setPartList ] = useState(undefined);
    const [ fetching, setFetching ] = useState(false);
    
    const getUserParts = useCallback(async () => {
        const params = userId ? [`/${userId}`] : []

            mounted.current && setFetching(true);
            
            await fetchFromApi({controller: "parts", method: "getGeneratedParts", params: params, refreshToken: true })           
                .then(({ userPartNums }) => {
                    if(mounted.current)
                    {
                        setPartList(userPartNums);
                        setFetching(false);
                        
                    }
                })
                .catch(error => console.log(error));
        
    }, [userId, mounted])

    useEffect(() => {
        mounted.current = true;
        getUserParts();

        return () => mounted.current = false;
    }, [getUserParts])
    
    return(
        <Box className={`${root} full-height`}>
        {
            partList && !fetching
                ?   <Box className="part-list-wrapper">
                        <FilterToolbar partList={partList}>
                        {
                            (filterList) => {
                                
                                return(
                                    
                                    <List
                                        dense
                                    >
                                    {                                       
                                        filterList.map(({ partNum, pullTime }, i) => (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={partNum}
                                                    secondary={pullTime}
                                                />
                                            </ListItem>
                                        ))                                        
                                    }
                                    </List>                                   
                                )
                            }
                        }
                        </FilterToolbar>
                    </Box>   
                   
                :   <CircularProgress color="primary" />
        }
        </Box>
    )
}

export default ViewParts;
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FileUpload from '../../ImageUpload';
import { envName } from '../../../baseUrl';
import apiFetch from '../../../apiFetch';
import useStyles from '../featureStyles';
import { LoadingOverlay, HelperText } from '../index';

const BugForm = () => {
    const classes = useStyles();

    const osList = [ "Windows", "Linux", "Android", "iOS" ];
    const browserList = [ "Chrome","Firefox","Edge","Safari","Opera","Other"];
    const initialFormState = {
        description: "", 
        steps: "", 
        selectedOS: "", 
        selectedBrowser: "",
        itemType: "bug",        
    };
    const formConstraints = {
        steps: { min: 250, max: 2000 },
        description: { min: 20, max: 75 },
        selectedOS: { min: 1 },
        selectedBrowser: { min: 1 }
    };
    const [ formValues, setFormValues ] = useState(initialFormState);  
    const [ fileValues, setFiles ] = useState([]); 
    const [ submitting, setSubmitting ] = useState(false);

    const handleChange = field => e => setFormValues({ ...formValues, [field]: e.target.value });   

    const handleFiles = async files => {
        
        const octets = await Promise.all(
            files.map(async file => {
                return new Promise(resolve => {
                    const reader = new FileReader();                    
                    reader.onload = () => {
                        
                        resolve([file.name, new Uint8Array(reader.result)]);
                    };
                    reader.readAsArrayBuffer(file);
                })
            })
            
        );
        
        setFiles(octets);
    }

    const submitForm = async () => {
        const { description, steps, selectedOS, selectedBrowser, itemType } = Object.fromEntries(
            Object.keys(formValues).map(key => ( [key, formValues[key].replace(/"/g, "\\\"").replace(/\//g, "\\/")] ))
        );
        setSubmitting(true);

        const attachmentUrls = await createAttachments();
        

        const body = { 
            title: description, 
            reproSteps: steps,
            // eslint-disable-next-line 
            sysInfo: {
                selectedOS,
                selectedBrowser,
                environment: envName
            }, 
            attachmentUrls,
            itemType 
        };        

        
        await apiFetch({ controller: "devOps", method: "createDevOpsRequest", 
            refreshToken: attachmentUrls.length === 0,
            fetchOptions: { 
                method: 'POST',
                body: JSON.stringify(body),            
            }
         }).then(data => {
             
             setFiles([]);
             setFormValues(initialFormState);   
             setSubmitting(false);         
         })
         .catch(() => setSubmitting(false)); 
    }

    const createAttachments = async () => {
        return await Promise.all( 
            fileValues.map(async ([fileName, file], i) => {
                const options = {
                    method: 'POST',
                    headers: {                       
                        'Content-Type': 'application/octet-stream'
                    },
                    body: file,
                }
                return await apiFetch({ controller: 'devOps' , method: 'createAttachment', 
                fetchOptions: options, params: [fileName], refreshToken: i===0 })                    
                    .then(data => {
                        
                        return new Promise((resolve) => {
                            resolve(data.url);
                        })
                    })
                    .catch()
            }
        ));       
    }

    return(
        
        <Grid className={classes.form} direction="column" container>
            <LoadingOverlay loading={submitting} />
            <Grid item>
                <Typography variant="h6">
                    Report a Bug
                </Typography>
                <Divider />
            </Grid>
            <Grid item container direction="column" className="form-body">
                <Typography variant="subtitle1" align="center" className={classes.customHeader}>
                    Bug Details
                </Typography>    
                <TextField 
                    helperText={
                        <HelperText 
                            min={`${formConstraints.description.min} characters min: ${formValues.description.length <= formConstraints.description.min ? formValues.description.length : formConstraints.description.min}/${formConstraints.description.min}`} 
                            max={`${formConstraints.description.max} max: ${formValues.description.length <= formConstraints.description.max ? formValues.description.length : formConstraints.description.max}/${formConstraints.description.max}`} 
                        />
                    }
                    label="Brief Description of Bug (Required)" 
                    variant="outlined" 
                    margin="dense" 
                    value={formValues.description} 
                    onChange={handleChange("description")}
                />                
                    
                <TextField 
                     helperText={
                        <HelperText 
                            min={`${formConstraints.steps.min} characters min: ${formValues.steps.length <= formConstraints.steps.min ? formValues.steps.length : formConstraints.steps.min}/${formConstraints.steps.min}`} 
                            max={`${formConstraints.steps.max} max: ${formValues.steps.length <= formConstraints.steps.max ? formValues.steps.length : formConstraints.steps.max}/${formConstraints.steps.max}`} 
                        />
                    }
                    label="Steps that lead to bug (Required)" 
                    variant="outlined" 
                    multiline
                    rows={5}
                    max={5}
                    margin="dense" 
                    value={formValues.steps}
                    onChange={handleChange("steps")}
                />
                <Typography variant="subtitle1" align="center" className={classes.customHeader}>
                    System Information
                </Typography>
                <div className={classes.sideBySide}>                    
                    <TextField
                        variant="outlined"
                        label="OS (Req)"
                        select
                        margin="dense"                                    
                        SelectProps={{                            
                            value: formValues.selectedOS,
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null                                            
                            },                            
                        }}
                        onChange={handleChange("selectedOS")}                        
                    >
                    { 
                        osList.map( (os,i) => (
                            <MenuItem key={i} value={os}>{os}</MenuItem>
                        ))
                    }
                    </TextField>
                    <TextField
                        variant="outlined"
                        label="Browser (Req)"
                        select
                        margin="dense"                                    
                        SelectProps={{                            
                            value: formValues.selectedBrowser,
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "center",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null                                            
                            },                            
                        }}
                        onChange={handleChange("selectedBrowser")}                        
                    >
                    {
                        browserList.map((browser,i) => (
                            <MenuItem key={i} value={browser}>{browser}</MenuItem>
                        ))
                    }
                    </TextField>
                </div>
                <Typography variant="subtitle1" align="center" className={classes.customHeader}>
                    Upload Screenshots
                </Typography>
                <FileUpload onChange={handleFiles} files={fileValues} imagesOnly />
                <Button 
                    variant="contained" 
                    disabled={Object.keys(formConstraints).some( key => formValues[key].length < formConstraints[key].min)} 
                    onClick={submitForm}
                >
                    Submit Report
                </Button>
            </Grid>
        </Grid>
       
        
    )
}
export default BugForm;
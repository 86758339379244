import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Box, Paper, Grid, IconButton, 
    SvgIcon, Snackbar, useMediaQuery, CircularProgress } from '@material-ui/core';
import PNGToolbar from './SubComponents/toolbar';
import homeStyles from './homeStyles';
import { CssTextField, StyledButton } from './SubComponents/styledComponents';
import fetchFromApi from '../../apiFetch';
import { useSelector } from 'react-redux';
import './home.css';

const Home = () => {
    //redux state user perms
    const permissions = useSelector(state => state.auth.user.permissions);
    //css hooks
    const classes = homeStyles();
    const matches = useMediaQuery('(max-width: 674px)');
    //input state
    
    const selectionOptions = {
        ...permissions.canPullProduction && { production: 'Production' },
        ...permissions.canPullNpd && { npd: 'NPD' },
    };
    const [ partSelection, setSelection ] = useState(
        Object.keys(selectionOptions)[0],
    );
    const [ count, setCount ] = useState(0); 
    //data state
    const [ fetching, setFetching ] = useState(false);   
    const [ partNums, setPartNums ] = useState(undefined);
    const [ error, setError ] = useState(undefined);
    //snackbar state
    const [ snackPack, setSnackPack ] = useState([]);
    const [ snackOpen, setSnackOpen ] = useState(false);   
    const [ snackMsg, setSnackMsg ] = useState(undefined);
    
    
    
    useEffect(() => {
        let unmounted = false;
        if (snackPack.length && !snackMsg && !unmounted) {          
            setSnackMsg({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setSnackOpen(true);
        } else if (snackPack.length && snackMsg && snackOpen && !unmounted) {          
            setSnackOpen(false);
        }
        
        return () => unmounted = true;
    }, [snackPack, snackMsg, snackOpen]);

    const handleChange = event => {
        setSelection(event.target.value);
    }

    const handleNumberChange = event => {
        if(!permissions.canBypassPartRestrict){
            if (event.target.value < 0 || event.target.value > 25)  return;
        } else {            
            if (event.target.value < 0) return;
        }

        setCount(event.target.value);
    }

    const handleGenerate = async () => {
        
        setFetching(true);
        setError(undefined);

        await fetchFromApi({
                controller: "parts",
                method: "getPartNumbers",
                params: [ selectionOptions[partSelection], count ],
                refreshToken: true,
            })
            .then(data => {    
                
                if(data.status !== 400) 
                    setPartNums(data.partNums);
                else
                    setError("It looks like an error occurred, please try again.");
                
                setFetching(false);
            })
            .catch(error => {
                console.log(error);
                setFetching(false);
                setError("It looks like an error occurred, please try again.");                
            }); 
    }
    
    const handleCopy = copyValue => async () => {

        const textArea = document.createElement("textarea");

        textArea.value = copyValue;
        textArea.style.display = 'none';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select(); 
        
        await new Promise((res,rej) => {
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
        })
            .then(() => {
                const message = copyValue + " copied to clipboard"
                setSnackPack(prev => [ ...prev, { message, key: new Date().getTime() } ]);
            })
            .catch(error => console.log(error));   
    }
   

    const handleClose = (e, reason) => {
        if(reason === 'clickaway'){
            return;
        }
            
        setSnackOpen(false);
    }

    const handleExited = () => {
        setSnackMsg(undefined);
    }
    
    return (
        <div className='container full-height centered'>            
              
            <Paper elevation={ 5 } square className={ `${ classes.fullWidth } ${ classes.noTopMargin }` }>
                <Box className={ classes.grid }>
                <FormControl className={ classes.formControl }>
                    <CssTextField
                        select
                        SelectProps={
                            {
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null
                                }
                            }
                        }
                        id='part-type'
                        label='Part Type'
                        onChange={ handleChange }
                        value={ partSelection }  
                        variant='outlined'  
                        margin={ matches ? "dense" : "none" }  
                    > 
                        
                        {
                            Object.keys(selectionOptions).map( key => {
                                return(
                                    <MenuItem key={key} value={key}>{selectionOptions[key]}</MenuItem>
                                )
                            })
                        }
                    </CssTextField>
                </FormControl>
                <FormControl className={ classes.formControlSm }>
                    <CssTextField
                        id='total-parts'
                        type='number'
                        label='Part Count'
                        variant='outlined'
                        onChange={ handleNumberChange }
                        margin={ matches ? "dense" : "none" }  
                        value={ count }                        
                    />
                </FormControl>
                <FormControl className={ classes.formControlButton }>
                    <StyledButton 
                        variant='contained' 
                        size='large' 
                        color='primary'
                        onClick={ handleGenerate } 
                        disabled={ !( count > 0 && partSelection !== '' ) || fetching }
                    >
                        Generate Part Number{ count > 1 && "s" }
                    </StyledButton>
                </FormControl>
                </Box>
            </Paper>
             
            <PNGToolbar error={error} fetching={fetching} snackSetter={setSnackPack} partNums={partNums} />
            {
                !fetching
                ?
                    <Box className={`${ classes.fullWidth } ${ classes.noTopMargin } ${ !error? classes.overFlowAuto : classes.loadingContainer }`}> 
                    {  
                        !error 
                        
                            ?   partNums && partNums.map((el,i) => (
                                    <Box key={ i } className={ classes.numberItem }>
                                        <Grid container justify='space-around' alignItems='center'>
                                            <Grid item>
                                                { el }
                                            </Grid>
                                            <Grid item>
                                                <IconButton 
                                                    className={ classes.icon } 
                                                    onClick={ handleCopy(el) }
                                                >
                                                    <SvgIcon>
                                                        <path d="M16 1H2v16h2V3h12V1zm-1 4l6 6v12H6V5h9zm-1 7h5.5L14 6.5V12z"></path>
                                                    </SvgIcon>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))                           
                            :
                                <Grid container className={classes.errorContainer} justify="center" alignContent='center'>
                                    <Grid item>
                                        { error }
                                    </Grid>
                                </Grid>
                        }                              
                    </Box> 
                :
                    <Grid container className={classes.loadingContainer} justify='center' alignContent='center'>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
            }
            <Snackbar            
                key={ snackMsg ? snackMsg.key : undefined }
                open={ snackOpen } 
                message={ snackMsg ? snackMsg.message : undefined } 
                autoHideDuration={ 4000 } 
                onClose={ handleClose }
                onExited={ handleExited } 
            />                
        </div>
    );
}

export default Home;
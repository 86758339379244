import makeStyles from '@material-ui/core/styles/makeStyles';

const makeDetermineStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: 750,
        maxWidth: '100%'
    },    
    layout: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems:  'center',
        maxHeight: '100%',
        maxWidth: '100%',
        '& .details-body': {
            padding: theme.spacing(3,1,1),
        },
        '& .details-header': {
            paddingBottom: theme.spacing(2),
        },
        '& .attachments-preview': {
            marginTop: 10,        
            marginBottom: 10,
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            flexWrap: 'nowrap',
            '& > div': {
                padding: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                flex: '0 0 120px',
                maxWidth: '120px',                
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '0.75em',
                fontWeight: 'bold',
                '&:not(:last-child)': {
                    marginRight: theme.spacing(1)
                },
                '& div': {
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                '& > .MuiSvgIcon-root': {
                    marginBottom: theme.spacing(1),
                    color: '#140a9a'
                },
                border: '1px solid #140a9a'
            }
        },
       
        '& .MuiButton-outlined.success': {
            border: '1px solid rgba(63, 127, 0, 0.75)',
            color: 'rgba(63, 127, 0, 0.65)',
            fontWeight: 'bolder',
            '&:hover': {
                backgroundColor: 'rgba(63, 127, 0, 0.07)',
            },
            '&:disabled': {
                border: '1px solid #b0b0b0',
                color: 'grey',
                fontWeight: 'bolder',
            }
        },
        '& .MuiButton-outlined.reject': {
            border: '1px solid rgba(127, 0, 42, 0.75)',
            color: 'rgba(127, 0, 42, 0.65)',
            fontWeight: 'bolder',
            '&:hover': {
                backgroundColor: 'rgba(127, 0, 42, 0.05)',
            }
        },
        '& .env-info': {
            padding: theme.spacing(1, 0),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            '& .MuiTypography-subtitle1': {
                textShadow: '0 0 1px currentColor'
            },
            [theme.breakpoints.down(550)]: {
                gridTemplateColumns: '1fr',
                '& > div:not(:last-child)': {
                    marginBottom: theme.spacing(1),
                }
                
            },

        },
        '& .button-container': {
            padding: theme.spacing(1),
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& button:first-child': {
                marginRight: theme.spacing(1)
            },
            [theme.breakpoints.down(550)]: {
                '& > button.MuiButton-root': {
                    flex: 1,
                    '& .MuiButton-label': {
                        justifyContent: 'center'
                    }
                }
            }
        }
    },
    flex: {
        display: 'flex'
    },
    customHeader: {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        textShadow: '1px 0 0 currentColor',
        marginTop: theme.spacing(1),
        '&::before, &::after': {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle',
            content: '""',
            width: '50%',            
            height: 2,
            backgroundColor: '#140a9a'
        },
        '&::before': {
            right: '0.5em',
            marginLeft: '-50%'
        },
        '&::after': {
            left: '0.5em',
            marginRight: '-50%'
        }
    },
    textBody: {
        margin: theme.spacing(1, 0),
        fontSize: 16
    }
}));

export default makeDetermineStyles;
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.down(450)]: {
            bottom: theme.spacing(3),
            right: 3,
        }
    },
    paper: {      
        display: 'flex',  
       
        width: 'calc(100% - 95px)',
        [theme.breakpoints.down(670)]:{
            width: 'calc(100% - 110px)',
        },
        maxWidth: '550px',        
        maxHeight: 'calc(100% - 28px)',        
        zIndex: 1500
    },
    popoverBody: {
        flexGrow: 1,  
        maxHeight: '100%',    
        position: 'relative',
        overflow: 'auto',
        '& > .MuiIconButton-root': {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 5,
        },
        '& .loading-overlay': {
            //zIndex: -1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'none',
            '&-visible': {
                backgroundColor: 'rgba(200,200,200, 0.4)',
                zIndex: 10,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
               
            }
        },
        
    },
    helperContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',   
        color: '#140a9a'
    },
    fabClass: {
        position: 'relative',
        color: '#140a9a',
        '&.active': {
            border: '2px solid #140a9a',
            
            '&::before': {
                display: 'inline-block',
                content: "''",
                width: 0,
                height: 0,
                position: 'absolute',
                left: -16,
                right: '100%',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderRight: '10px solid #140a9a'
            }
        }
    },
    mainButton: {
        backgroundColor: '#140a9a',
    },
    form: { 
        padding: theme.spacing(1), 
        position: 'relative',      
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        '& .MuiTypography-h6': {
            width: '100%',
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        '& .form-body': {
            flexGrow: 1,
            padding: theme.spacing(1),
            '& .MuiTextField-root:first-of-type': {
                marginBottom: theme.spacing(1),
            },
            '& .MuiButton-root': {
                marginTop: theme.spacing(1),
                '&:not(:disabled)': {
                    backgroundColor: '#140a9a',
                    color: 'white',
                }
            },
            '& .MuiButton-root span': {
                justifyContent: 'center',                
            }
        }
    },
    sideBySide: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(1),
    },
    customHeader: {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        textShadow: '1px 0 0 currentColor',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '&::before, &::after': {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle',
            content: '""',
            width: '50%',            
            height: 2,
            backgroundColor: '#140a9a'
        },
        '&::before': {
            right: '0.5em',
            marginLeft: '-50%'
        },
        '&::after': {
            left: '0.5em',
            marginRight: '-50%'
        }
    },
    

}));

export default useStyles;
import { makeStyles } from '@material-ui/core';

export const useAdminStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.background.dark,
        '& .MuiPaper-root': {
            padding: theme.spacing(3,1),
        },
        '& .MuiGrid-item': {
            padding: theme.spacing(1),
        },
        '&  .MuiIconButton-root': {
            color: '#140a9a',
        },
        '& .MuiListItemText-root': {
            marginRight: theme.spacing(3),
        },
        '& .MuiList-padding': {
            padding: theme.spacing(0),
            paddingTop: theme.spacing(1),
        },        
    },
    modalRoot: {
        '& .MuiGrid-item': {
            padding: theme.spacing(3, 0, 1),
            flexGrow: 1,
            '&.MuiGrid-item.permissions': {
                paddingRight: 0,
                '& > fieldset': {
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    '& legend': {
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        fontSize: '14px',
                        color: '#707070',
                    },
                    '&:hover': {
                        borderColor: '#0a0a0a',
                        
                    },
                    '& .MuiGrid-item': {
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(0),
                    },                    
                },
            },
            '&.Result-container': {
                display: 'flex',
                justifyContent: 'center',
            },
            '& .MuiFormControl-root': {
                width: '100%',                    
            },
            '&:nth-child(1)': {
                paddingRight: theme.spacing(1),
            },
            '&:nth-child(2)': {
                paddingLeft: theme.spacing(1),
            },
            '&:nth-child(3), &:nth-child(4)': {
                paddingTop: theme.spacing(1),
                '& .MuiGrid-item': {
                    flexGrow: 0,
                },
                '& .MuiButton-outlined.success': {
                    border: '1px solid rgba(63, 127, 0, 0.75)',
                    color: 'rgba(63, 127, 0, 0.65)',
                    fontWeight: 'bolder',
                    '&:hover': {
                        backgroundColor: 'rgba(63, 127, 0, 0.07)',
                    },
                    '&:disabled': {
                        border: '1px solid #b0b0b0',
                        color: 'grey',
                        fontWeight: 'bolder',
                    }
                },
                '& .MuiButton-outlined.reject': {
                    border: '1px solid rgba(127, 0, 42, 0.75)',
                    color: 'rgba(127, 0, 42, 0.65)',
                    fontWeight: 'bolder',
                    '&:hover': {
                        backgroundColor: 'rgba(127, 0, 42, 0.05)',
                    }
                }
            }
        },        
    },    
    outerContainer: {
        maxWidth: '100%',
    },
    fullHeightWithMargin: {
        height: `calc(var)`,
    },
    paper: {
        position: 'absolute',
        width: 'calc(100% - 18px)',
        maxHeight: '100vh',
        overflow: 'auto',
        maxWidth: '100ch',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 0,
        '& .MuiDivider-root': {
            marginBottom: theme.spacing(2),
        },
    },
    controlListItemWidth: {
        width: '100%',
        maxWidth: '65ch',
        '& .MuiPaper-root': {
            padding: theme.spacing(2),
        }
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        fontStyle: 'italic',
        color: 'rgba(0,0,0,0.4)',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'fixed',
        top: 0,
        right: 0,
        color: '#140a9a',
        '&:hover': {
            backgroundColor: 'inherit',
            color: "#5a5a5a",
        }
    },
    adminHomeRoot: {
        margin: '0 auto',
        maxWidth: '666px',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        
        '& > .MuiTypography-root': {
            marginBottom: theme.spacing(3),
        },
        '& .MuiPaper-root': {  
            display: 'flex',
            maxHeight: '100%',
            flexDirection: 'column',
            overflow: 'auto',
            padding: theme.spacing(3, 3, 2),
                 
            '& .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
            },            
            '& .grid-container': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',           
                gridGap: theme.spacing(2),
                width: '100%',
                maxHeight: '100%',
                padding: theme.spacing(3, 0, 1),
                overflow: 'auto',

                [theme.breakpoints.down(600)]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',                    
                    gridGap: theme.spacing(2),                    
                },
                [theme.breakpoints.down(400)]: {
                    gridTemplateColumns: 'repeat(1, 1fr)',                    
                    gridGap: theme.spacing(2),                    
                },    
                '& .grid-link': {
                    display: 'block',
                    position: 'relative',
                    '&:hover': {
                        boxShadow: theme.shadows[4],                                    
                    },
                    '&:active, &:focus': {
                        color: '#140a9a'
                    },                   
                    '& > .grid-link-inner': {
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        paddingTop: 'calc(3 / 4 * 100%)',
                        border: '3px solid #140a9a',
                        
                        '& > .grid-link-body': {
                            display: 'flex',
                            color: '#140a9a',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            padding: theme.spacing(2),
                            '& > .grid-link-header': {
                                fontWeight: 600,
                                textAlign: 'center'
                            },
                            ' & > .grid-link-icon': {                                
                                width: '33%',
                                flexGrow: 1,
                               
                                '& .MuiSvgIcon-root': {
                                    width: '100%',
                                    height: '100%',
                                    color: '#140a9a',                                    
                                }
                            },
                            '& .MuiBadge-badge': {
                                top: '90%',
                                right: '10%'
                            }
                        }
                    }
                }
            }
        },        
    }
}));

export default useAdminStyles;
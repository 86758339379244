import React, {useRef} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from './imgUploadStyles';

const FileUpload = ({ onChange = () => {}, files=[], imagesOnly=false, ...rest }) => {
    const classes = useStyles();
    const ref = useRef();

    const handleChange = e => {       
        
        
        onChange([...e.target.files]);
    }
    const handleDragAndDrop = (shouldTransfer = false) => e => {
        
        e.stopPropagation();
        e.preventDefault();
        if(shouldTransfer) {
            
            const dt = e.dataTransfer;
            const files = dt.files;
            ref.current.files=files;
            onChange([...files]);
            
        }
    }
    
    return (
        <div className={classes.upload}>
            <input 
                ref={ref}
                id="file-upload"
                type="file"
                multiple 
                accept={imagesOnly ? "image/*" : "image/jpg,image/png,image/bmp,image/jpeg,image/gif,text/plain,text/csv,application/pdf,application/rtf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                hidden                
                onChange={handleChange}
                {...rest} 
            />
            <fieldset>
                <legend>Click or Drag Onto</legend>                
                <label 
                    htmlFor="file-upload"                     
                    onDragOver={handleDragAndDrop()}
                    onDragEnter={handleDragAndDrop()}
                    onDrop={handleDragAndDrop(true)}
                >
                    <SvgIcon fontSize="large">
                    <path d="M2 12H4V17H20V12H22V17A2 2 0 0 1 20 19H4A2 2 0 0 1 2 17M11 5H13V8H16V10H13V13H11V10H8V8H11Z" />
                    </SvgIcon>
                </label>
                {
                    files.length > 0 
                    ?   <div className={classes.thumbnailContainer}> 
                        {    
                            
                            [...ref.current?.files].map((file,i) => {
                                let [,filename, extension] = /^(.*?)(\.[^.]*)?$/.exec(file.name);
                                return( 
                                    file.type.includes("image")
                                    ?
                                    <img key={i} src={URL.createObjectURL(file)} alt={file.name} />
                                    :
                                    <div key={i}>
                                        <SvgIcon fontSize="large">
                                        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"></path>
                                        </SvgIcon>
                                        <div>{filename}</div>
                                        <div>{extension}</div>
                                    </div>
                                    
                                )
                            })                                
                            
                        }
                        </div>
                    : <div className={classes.placeholder}>No Images Attached</div>
                }                
            </fieldset>
        </div>
    )
}

export default FileUpload;

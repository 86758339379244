import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FileUpload from '../../ImageUpload';
import useStyles from '../featureStyles';
import apiFetch from '../../../apiFetch';
import { envName } from '../../../baseUrl';
import { LoadingOverlay, HelperText } from '../index';

const FeatureForm = () => {
    const classes = useStyles();
    
    const initialFormState = {
        description: "", 
        title: "", 
        itemType: "feature",   
    };
    const formConstraints = {
        description: { min: 250, max: 2000 },
        title: { min: 20, max: 75 }
    };
    const [ formValues, setFormValues ] = useState(initialFormState); 
    const [ fileValues, setFiles ] = useState([]);
    const [ submitting, setSubmitting ] = useState(false);
    
    const handleChange = field => e => {
        if(e.target.value <= formConstraints[field].max)
            setFormValues({ ...formValues, [field]: e.target.value }); 
        else 
            setFormValues({...formValues, [field]: e.target.value.slice(0, formConstraints[field].max)});
    }  

    const handleFiles = async files => {
        const octets = await Promise.all(
            files.map(async file => {
                return new Promise(resolve => {
                    const reader = new FileReader();                    
                    reader.onload = () => {
                        
                        resolve([ file.name, new Uint8Array(reader.result) ]);
                    };
                    reader.readAsArrayBuffer(file);
                })
            })            
        );
        setFiles(octets);
     }   

    const submitForm = async () => {
        const { description, title, itemType } = Object.fromEntries(
            Object.keys(formValues).map(key => ( [key, formValues[key].replace(/"/g, "\\\"").replace(/\//g, "\\/")] ))
        );
       
        setSubmitting(true);

        const attachmentUrls = await createAttachments();       
        
        const body = { description, title, attachmentUrls, itemType, sysInfo: { environment: envName } };         

        await apiFetch({ controller: "devOps", method: "createDevOpsRequest", 
            refreshToken: attachmentUrls.length === 0,       
            fetchOptions: { 
                method: 'POST',
                body: JSON.stringify(body),            
            },
         }).then(data => {
             
            setFiles([]);
            setFormValues(initialFormState);   
            setSubmitting(false);          
         }).catch(error => console.log(error));         
    }

    const createAttachments = async () => {
        return await Promise.all( 
            fileValues.map(async ([fileName, file], i) => {
                const options = {
                    method: 'POST',
                    headers: {                       
                        'Content-Type': 'application/octet-stream'
                    },
                    body: file,
                }
                return await apiFetch({ controller: 'devOps' , method: 'createAttachment', 
                fetchOptions: options, params: [fileName], refreshToken: i===0 })                    
                    .then(data => {
                        
                        return new Promise((resolve) => {
                            resolve(data.url);
                        })
                    })
                    .catch()
            }
        ));       
    }

    return (
        
            <Grid className={classes.form} direction="column" container>
                <LoadingOverlay loading={submitting} />
                <Grid item>
                    <Typography variant="h6">
                        Request A Feature
                    </Typography>
                    <Divider />
                </Grid>
                <Grid item container direction="column" className="form-body">
                    <Typography variant="subtitle1" align="center" className={classes.customHeader}>
                        Feature Details
                    </Typography>    
                    <TextField 
                        label="Brief Description of Feature (Required)" 
                        variant="outlined" 
                        helperText={
                            <HelperText 
                                min={`${formConstraints.title.min} characters min: 
                                    ${formValues.title.length <= formConstraints.title.min 
                                        ? formValues.title.length 
                                        : formConstraints.title.min
                                    }/${formConstraints.title.min}`} 
                                max={`${formConstraints.title.max} max: ${formValues.title.length}/${formConstraints.title.max}`} 
                            />
                        }         
                        margin="dense"                        
                        value={formValues.title}                    
                        onChange={handleChange("title")}
                    /> 
                    <TextField   
                        helperText={
                            <HelperText 
                                min={`${formConstraints.description.min} characters min: ${formValues.description.length <= formConstraints.description.min ? formValues.description.length : formConstraints.description.min}/${formConstraints.description.min}`} 
                                max={`${formConstraints.description.max} max: ${formValues.description.length <= formConstraints.description.max ? formValues.description.length : formConstraints.description.max}/${formConstraints.description.max}`} 
                            />
                        }                      
                        label="Detailed Description of Feature (Required)" 
                        variant="outlined"  
                        multiline
                        rows={5}                        
                        margin="dense"                       
                        value={formValues.description}
                        onChange={handleChange("description")}
                    />
                    <Typography variant="subtitle1" align="center" className={classes.customHeader}>
                        Add File Attachments
                    </Typography>  
                    <FileUpload onChange={handleFiles} name="userUpload" files={fileValues} />
                    <Button 
                        variant="contained" 
                        disabled={ Object.keys(formConstraints).some( key => formValues[key].length < formConstraints[key].min) } 
                        onClick={submitForm}
                    >
                        Submit Request
                    </Button>
                </Grid>
            </Grid>          
        
    )
}
export default FeatureForm;



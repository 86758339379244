const baseUrl = window.location.hostname.includes("localhost") 
    ? "https://localhost:5001" 
    : window.location.hostname.includes("kpng-beta.mining.komatsu")
        ? "https://kmc-kpng-api-beta.azurewebsites.net"
        : "https://kmc-kpng-api.azurewebsites.net";

export default baseUrl;

const envName = window.location.hostname.includes("localhost") 
    ? "LOCAL" 
    : window.location.hostname.includes("kpng-beta.mining.komatsu")
        ? "DEVELOPMENT: https://kpng-beta.mining.komatsu"
        : "PRODUCTION: https://kpng.mining.komatsu";

export { envName };

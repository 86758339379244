const endpoints = {
    parts: {
        base: '/api/partnumbers',
        getPartNumbers: '/getpartnumbers/',
        getGeneratedParts: '/getgeneratedparts',
    },
    users: {
        base: '/api/users',
        getUserAndPermissions: '/getuserandpermissions',
        accessRequest: '/submitrequest',
        checkRequest: '/checkforaccessrequest',   
        submitAdditionalRequest: '/submitadditionalrequest',
        checkAdditionalRequest: '/checkadditionalrequestbyuser',     
    },
    admin: {
        base: '/api/admin',
        getPendingRequests: '/getpendingrequests',
        getBadgeCounts: '/updatebadges',
        determineRequest: '/determinerequest',
        getAllUsers: '/getallusers',
        userQuery: '/getusersbyquery',
        updateUserPermissions: '/updateuserpermissions/',
        getRegisteredUserPermissions: '/getRegisteredUserPermissions/',
        getAdditionalRequests: '/getadditionalrequests',   
        determinePermissionRequest: '/determinepermissionrequest/', 
    },
    devOps: {
        base: '/api/DevOps',
        createDevOpsRequest: '/createdevopsrequest/',
        createAttachment: '/createattachment/',
        createAttachments: '/createattachments',
        getItem: '/getworkitem/',
        determineItem: '/submitworkitem/',
    }
}

export default endpoints;
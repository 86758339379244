import makeStyles from '@material-ui/core/styles/makeStyles';

const imgUploadStyles = makeStyles(theme => ({
    upload: {
        display: 'block',
        maxWidth: '100%',
        width: '100%',
        '& fieldset':{
            display: 'block',
            border: '1px solid rgba(0,0,0,.25)',
            borderRadius: '4px',
            margin: 0,
            width: '100%',
            minWidth: 0,
            '& > legend': {
                marginTop: 0,
                paddingTop: 0,
                paddingLeft: '5px',
                paddingRight: '5px',
                fontSize: '0.75em',
                color: 'rgba(0,0,0,.54)',
            },
            '& > label': {
                width: '100%',
                cursor: 'pointer',
                border: '1px dashed #140a9a',
                
                padding: theme.spacing(4,1),
                display: 'flex',
                justifyContent: 'center',
                transition: 'background-color 150ms ease-in',
                '&:hover': {
                    backgroundColor: 'rgba(173, 216, 230, 0.2)'
                },
                '& > .MuiSvgIcon-root': {
                    color: '#140a9a'
                }
            }    
        } 

    },
    thumbnailContainer: {
        marginTop: 10,
        
        height: 100,
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        '& img': {
            flex: '0 0 auto',
            border: '1px solid lightgrey',
            
            '&:not(:last-child)': {
                marginRight: 5,
            }
        },
        '& > div': {
            padding: '4px 8px',
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 1',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.75em',
            fontWeight: 'bold',
            '& > .MuiSvgIcon-root': {
                color: '#140a9a'
            },
            border: '1px solid rgba(0,0,0,.25)'
        }
    },
    
    placeholder: {
        marginTop: 10,
        height: 100,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'italic'
    }   
   
    
}));

export default imgUploadStyles;
import React, { useCallback, useEffect, useState, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useParams } from 'react-router-dom';
import fetchFromApi from '../../apiFetch';
import useStyles from './determineStyles';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

const DetermineWorkItem = ({ id }) => {
    const [ item, setItem ] = useState(null);    
    const [ isValid, setIsValid ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ submitted, setSubmitted ] = useState(null);

    const classes = useStyles();

    const getItem = useCallback(async () => {
        setLoading(true);
        await fetchFromApi({ controller: "devOps", method: "getItem", params: [id], refreshToken: true })
            .then(data => {
                console.log(data);

                if(data?.valid){
                    setItem(data.item);
                    setIsValid(data?.valid);
                }
                else
                    
                    setMessage(data?.message);
                    
                setLoading(false);

            }).catch(error =>{ 
                console.log(error);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        
        if(!item) {
            getItem();
        }
        if(submitted){
            getItem();
        }
    }, [getItem, item, submitted]);

    const submitDetermination = acceptState => async () => {
        const body = { ...item, acceptanceState: acceptState };
        setLoading(true);
        await fetchFromApi({ 
            controller: "devOps", 
            method: "determineItem", 
            params: [id], 
            refreshToken: true, 
            fetchOptions: {
                method: 'POST',
                body: JSON.stringify(body),            
                
            }})
            .then(data => {
                setLoading(false);
                setIsValid(false);
                setMessage(data.message);
                setTimeout(() => setSubmitted(true), 2750);                
            })
            .catch(() => setLoading(false));

        
    }
    return(
        <div className={`full-height container ${classes.flex}`}>
            <div className={`${classes.layout}`}>                        
            {
                !loading
                    ?
                    <Paper square elevation={2} classes={{ root: classes.root }}>
                    {    item && isValid
                        ?
                        <Fragment>
                            <Typography variant="h5" className="details-header">
                                {`${item.itemType[0].toUpperCase()}${item.itemType.slice(1)} ${item.itemType === 'bug' ? 'Report' : 'Request'} Details`}
                            </Typography>
                            <Divider />
                            <div className="details-body">                           
                            {
                                [
                                    ["title","Basic Description"],
                                    ["reproSteps","Steps To Reproduce"],
                                    ["description","Detailed Description"],                                
                                ].map(([key, title], i) => (
                                    item[key]
                                    &&
                                    <Fragment key={i}>
                                        <Typography 
                                            className={classes.customHeader} 
                                            align="center" 
                                            variant="h6"
                                        >   
                                            {title}
                                        </Typography>  
                                        <Typography
                                            className={classes.textBody}
                                        >
                                            {item[key]}
                                        </Typography>
                                    </Fragment>
                                ))
                            }
                            {
                                item?.sysInfo && 
                                <Fragment>
                                    <Typography 
                                        className={classes.customHeader} 
                                        align="center" 
                                        variant="h6"
                                    >System Info</Typography>
                                    <div className="env-info">
                                    {
                                        Object.keys(item?.sysInfo).map((prop,i) => {
                                            const formatted = prop
                                                .match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+/g)
                                                .join(' ');
                                                
                                            return(
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        {`${formatted[0].toUpperCase()}${formatted.slice(1)}`}
                                                    </Typography>
                                                    <div key={i}>{item.sysInfo[prop]}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </Fragment>
                            }
                            {
                                item?.attachmentUrls 
                                &&
                                item?.attachmentUrls?.length > 0
                                &&
                                <Fragment>
                                    <Typography 
                                        className={classes.customHeader} 
                                        align="center" 
                                        variant="h6"
                                    >
                                        Attachments
                                    </Typography>
                                {
                                    <div className="attachments-preview">
                                    {
                                        item?.attachmentUrls.map((url, i) => {
                                            let [,filename, extension] = /^(.*?)(\.[^.]*)?$/.exec(url.split('=')[1]);
                                            let imgExtensions = ['.jpg', '.jpeg', '.bmp', '.png', '.gif']
                                            return(
                                                <div key={i}>
                                                    <SvgIcon fontSize="large">
                                                    {
                                                        imgExtensions.includes(extension)
                                                        ?
                                                        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"></path>
                                                        :
                                                        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z"></path>
                                                    }
                                                    </SvgIcon>
                                                    <div>{filename}</div>
                                                    <div>{extension}</div>
                                                </div>
                                            )
                                        }) 
                                    }
                                    </div>
                                }
                                </Fragment>
                            }      
                            </div>
                            <div className="button-container">
                                <Button 
                                    variant="outlined"
                                    className="success"
                                    onClick={submitDetermination(true)}
                                    startIcon={
                                        <SvgIcon>
                                            <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                        </SvgIcon>
                                    }
                                >
                                    Approve
                                </Button>
                                <Button 
                                    variant="outlined"
                                    onClick={submitDetermination(false)}
                                    className="reject"
                                    startIcon={
                                        <SvgIcon>
                                            <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                        </SvgIcon>
                                    }
                                >
                                    Reject
                                </Button>
                            </div>   
                        </Fragment>
                        :
                        <Typography variant="h5" align="center">{message}</Typography>
                    }
                    </Paper>
                    :   
                     <CircularProgress color="primary" />                    
                    
            } 
            </div>             
        </div>
    )
}

const WorkItemRouteWrapper = () => {
    const { id } = useParams();
    const foundId = id.match(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/g);

    return(
        <DetermineWorkItem id={foundId ? foundId[0] : '00000000-0000-0000-0000-000000000000'} />
    )
}

export default WorkItemRouteWrapper;
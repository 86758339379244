import { makeStyles } from '@material-ui/core';


const navStyles = makeStyles(theme => ({
        navColor: {
            backgroundColor: theme.navColor,
            //backgroundImage: 'linear-gradient(to bottom, forestgreen, turquoise,aqua)'
        },
        navRoot: {
            '& .MuiBadge-badge': {
                height: '12px',
                minWidth: '12px',
            },
            '& .MuiListItemSecondaryAction-root .MuiBadge-badge': {
                height: '20px',
                minWidth: '20px',
                marginRight: '15px',
                
            },
            '& .app-logo': {
                maxHeight: 84,
                [theme.breakpoints.down(768)]: {
                    maxHeight: 75
                },
                [theme.breakpoints.down(500)]: {
                    maxHeight: 50
                }
            },
            '& .k-logo': {
                
                
                maxHeight: 32,
                marginRight: 2,
            },
            '& .logo-container': {
                display: 'flex',
                justifyContent: 'center'
            }
        }
    }
))

export default navStyles;
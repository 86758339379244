import { useState, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

const useConnection = (url, token) => {
    const [ connection, setConnection ] = useState(null);
    

    useEffect(() => {
        
        if (!connection && token){
            
            const newConnection = new HubConnectionBuilder()
                .withUrl(url, { accessTokenFactory: () => token})                
                .build();

            setConnection(newConnection);

            
        }

    }, [ connection, url, token ])
    

    return [ connection ];
}

export default useConnection;